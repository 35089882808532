<template>
  <div
    class="ui-form-generator"
    :class="{'--inline': inline}"
  >
    <template v-for="(field,i) in schema.fields">
      <select
        :key="i"
        v-if="field.type == 'select-native'"
        v-bind="field.props"
        :value="getFieldValue(field) || false"
        @change="setFieldValue(field, $event.target.value == 'true')"
      >
        <option
          v-for="option in field.options"
          :key="option.value"
          :value="option.value"
        >{{ option.text }}</option>
      </select>
      <ui-field
        v-else
        :key="i"
        :label="field.type == 'checkbox' ? '' : field.label"
      >
        <template v-if="field.type == 'static'">
          <label v-text="getFieldValue(field)"></label>
        </template>
        <template v-else-if="field.type == 'select'">
          <ui-select
            v-bind="field.props"
            :value="getFieldValue(field)"
            @input="setFieldValue(field, $event)"
            :options="field.options"
          ></ui-select>
        </template>
        <template v-else-if="field.type == 'textarea'">
          <textarea
            class="ui-native"
            v-bind="field.props"
            :value="getFieldValue(field)"
            @input="setFieldValue(field, $event.target.value)"
          ></textarea>
        </template>
        <template v-else-if="field.type == 'date'">
          <ui-input-date
            v-bind="field.props"
            :value="getFieldValue(field)"
            @input="setFieldValue(field, $event)"
          ></ui-input-date>
        </template>
        <template v-else-if="field.type == 'json'">
          <ui-input-json
            v-bind="field.props"
            :value="getFieldValue(field)"
            @input="setFieldValue(field, $event)"
          ></ui-input-json>
        </template>
        <template v-else-if="field.type == 'checkbox'">
          <label class="ui-clickable">
            {{ field.label }}
            <input
              v-bind="field.props"
              type="checkbox"
              :checked="getFieldValue(field)"
              @change="setFieldValue(field, $event.target.checked)"
            />
          </label>
        </template>
        <template v-else>
          <input
            class="ui-native"
            v-bind="field.props"
            :type="field.type"
            :value="getFieldValue(field)"
            @input="setFieldValue(field, $event.target.value)"
            :title="field.label"
          />
        </template>
      </ui-field>
    </template>
  </div>
</template>

<script>
import { getProperty, setProperty } from '@/modules/cms/functions';
import {
  UiField,
  UiSelect,
  UiInputDate,
  UiInputJson,
} from '@/modules/ui/components';

export default {
  name: 'ui-form-generator',
  components: { UiField, UiSelect, UiInputDate, UiInputJson },

  props: {
    schema: {
      type: Object,
      required: true,
    },

    value: {
      required: false,
      default: null,
    },

    inline: {
      required: false,
      default: false,
    },
  },

  data() {
    return {
      innerValue: {},
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        // garantizar que incomingValue tenga todas las propiedades en cada field.model
        // Esto garantiza que todos las propiedades de innerValue sean reactivas
        let incomingValue = newValue
          ? JSON.parse(JSON.stringify(newValue))
          : {};
        this.schema.fields.forEach((field) => {
          setProperty(
            incomingValue,
            field.model,
            getProperty(incomingValue, field.model) || null
          );
        });

        this.innerValue = incomingValue;
      },
    },
  },

  methods: {
    getFieldValue(field) {
      return getProperty(this.innerValue, field.model);
    },

    setFieldValue(field, value) {
      setProperty(this.innerValue, field.model, value);
      this.$emit('input', this.innerValue);
    },
  },
};
</script>

<style lang="scss">
.ui-form-generator {
  &.--inline {
    display: inline-flex;
  }
}
</style>